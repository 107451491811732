// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .error-header-bar_R2rWE{display:flex;align-items:center;justify-content:center;width:100%;padding-top:var(--StatusbarHeight);background-color:var(--Layer1);min-width:768px;height:56px;margin:0 auto}@media(min-width:1280px){html.app__layout--desktop .error-header-bar_R2rWE{height:56px}}html.app__layout--desktop .error-header-bar__inner_wKTQO{display:flex;width:100%;max-width:1440px;padding:0 16px}@media(min-width:1280px){html.app__layout--desktop .error-header-bar__inner_wKTQO{padding:0 16px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-header-bar": `error-header-bar_R2rWE`,
	"error-header-bar__inner": `error-header-bar__inner_wKTQO`
};
export default ___CSS_LOADER_EXPORT___;
