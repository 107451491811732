// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .header-bar-simple_KmWs4{display:flex;align-items:center;width:100%;height:56px;padding:0 16px;margin:0 auto;background-color:var(--Layer1)}html.app__layout--desktop .header-bar-simple__container_gn5iQ{width:1440px;margin:0 auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-bar-simple": `header-bar-simple_KmWs4`,
	"header-bar-simple__container": `header-bar-simple__container_gn5iQ`
};
export default ___CSS_LOADER_EXPORT___;
