// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .error-app_xJgME{display:flex;flex-direction:column;justify-content:center;min-height:calc(100vh - 48px);padding:16px;min-height:calc(100vh - 56px)}@media(min-width:1280px){html.app__layout--desktop .error-app_xJgME{min-height:calc(100vh - 56px)}}html.app__layout--desktop .error-app__info_GDUcC,html.app__layout--desktop .error-app__support_X4A_A{max-width:311px}html.app__layout--desktop .error-app__support_X4A_A{margin-top:64px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-app": `error-app_xJgME`,
	"error-app__info": `error-app__info_GDUcC`,
	"error-app__support": `error-app__support_X4A_A`
};
export default ___CSS_LOADER_EXPORT___;
