// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .error-page_JU6ah{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%;padding:0 16px;text-align:center;background-color:var(--Layer0)}html.app__layout--desktop .error-page--not-found_K1gzl{height:calc(100vh - 56px)}html.app__layout--desktop .error-page__holder_wxmK0{position:relative;width:100%;padding:32px;max-width:375px}html.app__layout--desktop .error-page__holder--plain_J_8sD{padding-bottom:0}html.app__layout--desktop .error-page__holder--slott_m7kua{padding-top:168px}html.app__layout--desktop .error-page--technical__mA_m{height:calc(100vh - 56px)}html.app__layout--desktop .error-page__error-number_eOocS{font-size:80px;font-weight:500;line-height:80px}html.app__layout--desktop .error-page__description_XyQqJ{margin-top:12px}html.app__layout--desktop .error-page__footer_SDDO5{display:inline-block}html.app__layout--desktop .error-page__code_mVk2d{font-size:12px;font-weight:400;line-height:16px;letter-spacing:-.7px;position:absolute;right:0;left:0;display:block;padding:12px;margin:16px 0;border:1px solid var(--Layer1)}html.app__layout--desktop .error-page__hint_Zo3f7{margin:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-page": `error-page_JU6ah`,
	"error-page--not-found": `error-page--not-found_K1gzl`,
	"error-page__holder": `error-page__holder_wxmK0`,
	"error-page__holder--plain": `error-page__holder--plain_J_8sD`,
	"error-page__holder--slott": `error-page__holder--slott_m7kua`,
	"error-page--technical": `error-page--technical__mA_m`,
	"error-page__error-number": `error-page__error-number_eOocS`,
	"error-page__description": `error-page__description_XyQqJ`,
	"error-page__footer": `error-page__footer_SDDO5`,
	"error-page__code": `error-page__code_mVk2d`,
	"error-page__hint": `error-page__hint_Zo3f7`
};
export default ___CSS_LOADER_EXPORT___;
