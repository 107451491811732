import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { Tag } from 'web/src/components/_shared';
import STag from 'web/src/components/Tag/STag/STag.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ErrorPageNavigation',
    props: {
        items: {}
    },
    setup (__props) {
        const props = __props;
        const router = useRouter();
        const buttonList = computed(()=>props.items.map((param)=>{
                let { id, routeName, props: itemProps, caption } = param;
                const routerParameters = {};
                if (itemProps) for (const key of Object.keys(itemProps))routerParameters[key] = `${itemProps[key]}`;
                const to = {
                    name: routeName || '',
                    params: routerParameters
                };
                return {
                    id,
                    tag: Tag.A,
                    label: caption,
                    kind: ButtonKind.BASE,
                    href: router.resolve(to).href,
                    to
                };
            }));
        function onClick(location) {
            router.push(location);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("nav", {
                class: _normalizeClass(_ctx.$style['error-page-nav'])
            }, [
                _createElementVNode("ul", {
                    class: _normalizeClass(_ctx.$style['error-page-nav__list'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(buttonList.value, (param)=>{
                        let { id, to, ...buttonProps } = param;
                        return _openBlock(), _createElementBlock("li", {
                            key: id,
                            class: _normalizeClass(_ctx.$style['error-page-nav__list-item'])
                        }, [
                            (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 1,
                                class: _ctx.$style['error-page-nav__button'],
                                ref_for: true
                            }, buttonProps, {
                                onClick: _withModifiers(($event)=>onClick(to), [
                                    "prevent"
                                ])
                            }), null, 16, [
                                "class",
                                "onClick"
                            ]))
                        ], 2);
                    }), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ErrorPageNavigation'
                ]
            ]);
        };
    }
});
