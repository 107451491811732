// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .error-page-nav_EcIKv{margin-top:-12px}html.app__layout--desktop .error-page-nav__list_uJYMX{display:flex;flex-wrap:wrap;justify-content:center;padding:0;margin:0;list-style:none}html.app__layout--desktop .error-page-nav__list-item_A0e65{display:flex;padding:0;margin:0 4px 8px}html.app__layout--desktop .error-page-nav__button_VbD5f{min-width:88px;width:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-page-nav": `error-page-nav_EcIKv`,
	"error-page-nav__list": `error-page-nav__list_uJYMX`,
	"error-page-nav__list-item": `error-page-nav__list-item_A0e65`,
	"error-page-nav__button": `error-page-nav__button_VbD5f`
};
export default ___CSS_LOADER_EXPORT___;
